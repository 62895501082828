const JavascriptLogo = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
            <g clip-path="url(#clip0_21_3161)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 8.75C0.75 4.33172 4.33172 0.75 8.75 0.75H55.25C59.6683 0.75 63.25 4.33172 63.25 8.75V55.25C63.25 59.6683 59.6683 63.25 55.25 63.25H8.75C4.33172 63.25 0.75 59.6683 0.75 55.25V8.75ZM17.1835 52.9795C17.1835 52.9795 17.1836 52.9795 17.1836 52.9795L17.1836 52.9795C18.5681 55.9159 21.2955 58.3491 25.9937 58.3491C31.1962 58.3491 34.7621 55.5802 34.7621 49.4968V32.3803C34.7621 30.7584 33.4473 29.4436 31.8254 29.4436C30.2035 29.4436 28.8887 30.7584 28.8887 32.3803V49.4138C28.8887 52.3503 27.6719 53.1053 25.742 53.1053C22.9352 53.1053 19.9798 51.2873 17.5785 52.7405L17.1836 52.9795L17.1836 52.9795C17.1836 52.9794 17.1835 52.9794 17.1835 52.9795ZM44.5055 51.7441C43.3176 50.731 41.6567 50.2054 40.3056 50.9877C38.9851 51.7523 38.4617 53.4524 39.471 54.5968C41.4409 56.8303 44.4821 58.3496 48.6505 58.3496C54.2303 58.3496 58.3836 55.4551 58.3836 50.1686C58.3836 45.2601 55.5726 43.0788 50.5801 40.939L49.1118 40.3097C46.5948 39.2188 45.504 38.5055 45.504 36.7434C45.504 35.3172 46.5948 34.2264 48.315 34.2264C49.0314 34.2264 49.6407 34.3564 50.1822 34.6457C51.5566 35.3802 53.1984 36.0319 54.5097 35.1898C55.7177 34.4141 56.1479 32.7845 55.1649 31.7382C53.4061 29.8663 51.1663 29.1077 48.3149 29.1077C43.0704 29.1077 39.7142 32.4642 39.7142 36.8693C39.7142 41.6522 42.5252 43.9177 46.7628 45.7213L48.2308 46.3511C50.9159 47.5254 52.51 48.2387 52.51 50.2525C52.51 51.9307 50.9581 53.1474 48.5245 53.1474C46.8005 53.1474 45.5229 52.6117 44.5055 51.7441Z" fill="yellow"/>
            </g>
            <defs>
                <clipPath id="clip0_21_3161">
                <rect width="64" height="64" rx="8"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default JavascriptLogo