const PythonLogo = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
            <g clip-path="url(#clip0_21_3178)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2556 6.54448C14.2556 6.54448 13.4216 0.752931 26.7767 0.75293C40.7333 0.75293 39.6551 7.73554 39.6551 7.73554V19.274C39.6551 26.2523 32.5533 26.1375 32.5533 26.1375H19.9132C12.3333 26.1375 12.5732 33.5965 12.5732 33.5965V39.8496H8.12164C8.12164 39.8496 0.662597 40.3275 0.662598 26.8521C0.662598 13.3768 9.20848 14.346 9.20848 14.346H27.0149V12.5445H14.2705L14.2556 6.54448ZM22.0422 7.08046C22.0422 5.81249 21.0174 4.78766 19.7494 4.78766C18.4814 4.78766 17.4566 5.81249 17.4566 7.08046C17.4566 8.34843 18.4814 9.37326 19.7494 9.37326C21.0174 9.37326 22.0422 8.34843 22.0422 7.08046Z" fill="#FFDE57" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M39.677 47.2949C39.677 47.2949 40.511 53.0864 27.156 53.0864C13.1994 53.0864 14.2776 46.1038 14.2776 46.1038V34.5654C14.2776 27.5871 21.3793 27.7019 21.3793 27.7019H34.0195C41.5994 27.7019 41.3594 20.2428 41.3594 20.2428V13.9897H45.811C45.811 13.9897 53.2701 13.5119 53.2701 26.9872C53.2701 40.4626 44.7242 39.4934 44.7242 39.4934H26.9178V41.2949H39.6622L39.677 47.2949ZM31.8905 46.7589C31.8905 48.0269 32.9153 49.0517 34.1833 49.0517C35.4512 49.0517 36.4761 48.0269 36.4761 46.7589C36.4761 45.4909 35.4512 44.4661 34.1833 44.4661C32.9153 44.4661 31.8905 45.4909 31.8905 46.7589Z" fill="#4584B6" />
            </g>
            <defs>
                <clipPath id="clip0_21_3178">
                <rect width="54" height="54" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default PythonLogo