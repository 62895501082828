const MongoLogo = ({color}) => {
    return (
        <svg width="29" height="64" viewBox="0 0 29 64" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 27">
                <path id="Path" fill-rule="evenodd" clip-rule="evenodd" d="M13.0265 56.0151C14.1937 58.7528 14.082 63.418 14.082 63.418L15.7858 64.0001C15.7858 64.0001 15.4534 59.4286 15.9523 57.2675C16.0919 56.5949 16.4291 56.0076 16.8303 55.5161C16.7455 55.5756 16.7004 55.6053 16.7004 55.6053C15.0399 54.8275 14.5488 51.2224 14.4689 47.8433C14.1377 52.0255 13.6642 55.0778 13.2095 56.1454C13.2095 56.1454 13.1455 56.1017 13.0265 56.0151Z" fill="white"/>
                <path id="Combined Shape" fill-rule="evenodd" clip-rule="evenodd" d="M14.0836 0.268245L14.082 0.166419C14.082 0.166419 14.9966 2.49356 15.9523 3.82335C16.8248 5.69362 24.7628 10.5559 28.1288 25.3921C31.6198 45.7974 16.7004 55.6052 16.7004 55.6052C15.0399 54.8275 14.5488 51.2223 14.469 47.8432C14.1377 52.0255 13.6642 55.0778 13.2095 56.1453C13.2095 56.1453 -0.795719 46.5867 0.0355966 29.7559C0.824919 12.9247 10.7159 4.65467 12.6274 3.15845C13.8744 1.82866 13.916 1.32979 13.9992 0C14.0285 0.0627441 14.0566 0.152757 14.0836 0.268254V0.268245Z" fill="green" />
            </g>
        </svg>
    )
}

export default MongoLogo