const DockerLogo = ({color}) => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g id="Docker">
                <path id="symbol" d="M49.8886 17.9996L51.2697 18.9996C53.6717 20.937 55.0528 23.1244 55.353 25.6243C57.9351 25.1243 61.2378 25.4993 62.6789 26.4993L64 27.3118L63.7598 27.8117C63.3395 28.7492 63.2194 28.9992 63.1593 29.1242C60.8174 33.8115 56.1937 33.874 54.032 33.8115C48.5676 47.4984 36.9182 55.0606 21.1855 55.0606C13.9197 55.0606 8.39522 52.7482 4.67222 48.1859C-0.251743 42.1862 -0.251743 34.374 0.168596 31.8741L0.288692 31.3741H43.7037C45.9855 31.3741 47.7269 30.7491 48.5676 30.2491C47.6668 28.8742 47.3666 27.3118 47.3066 26.4368C47.1264 23.9369 47.7269 21.312 48.9279 19.4371L49.8886 17.9996ZM11.5778 23.8744V30.1241H4.97246V23.8744H11.5778ZM19.3841 23.8744V30.1241H12.7788V23.8744H19.3841ZM27.1904 23.8744V30.1241H20.585V23.8744H27.1904ZM34.9966 23.8744V30.1241H28.3913V23.8744H34.9966ZM42.8029 23.8744V30.1241H36.1976V23.8744H42.8029ZM34.9966 16.3747V22.6244H28.3913V16.3747H34.9966ZM27.1904 16.3747V22.6244H20.585V16.3747H27.1904ZM19.3841 16.3747V22.6244H12.7788V16.3747H19.3841ZM34.9966 8.875V15.1247H28.3913V8.875H34.9966Z" fill="#0DB7ED"/>
            </g>
        </svg>
    )
}

export default DockerLogo